<template>
  <div class="head">
    <div class="head_box">
      <img src="@/assets/home_logo.png" class="head_img" @click="toPage('/')" />

      <div class="head_menu">
        <div class="menu_item" @click="toPage('/')">
          <div class="tool_tit">首页</div>
        </div>
        <div class="menu_item">
          <div class="tool_tit">产品和服务</div>
          <div class="tool_tip_unlita">
            <div class="tool_tip_un_box">
              <p class="tool_tip_big_tit">产品</p>
              <!-- <div class="tool_tip_big_item" @click="toPage('/pulife')">
                <img src="@/assets/home/head_pulife.png" />
                <div>
                  <p>菩享汇</p>
                  <p>以支付引爆营销的新商家平台</p>
                </div>
              </div> -->
              <!-- <div class="tool_tip_big_item" @click="toPage('/qw')">
                <img src="@/assets/home/head_qw.png" />
                <div>
                  <p>菩成私域宝</p>
                  <p>精细化运营管理私域用户</p>
                </div>
              </div> -->
              <div class="tool_tip_big_item" @click="toPage('/life')">
                <img src="@/assets/home/head_life.png" />
                <div>
                  <p>菩成生活</p>
                  <p>本地生活+AI电商线上线下一体化</p>
                </div>
              </div>
            </div>

            <!-- <div class="tool_tip_un_box">
              <p class="tool_tip_big_tit">服务</p>
              <div class="tool_tip_big_item" @click="toPage('/business')">
                <img src="@/assets/home/head_business.png" />
                <div>
                  <p>私域运营</p>
                  <p>全链路私域增长解决方案</p>
                </div>
              </div>
              <div class="tool_tip_big_item" @click="toPage('/douyin')">
                <img src="@/assets/home/head_douyin.png" />
                <div>
                  <p>抖音代运营</p>
                  <p>为商家抢占视频营销红利</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="menu_item">
          <div class="tool_tit">解决方案</div>
          <div class="tool_tip_big">
            <div class="tool_tip_big_box">
              <p class="tool_tip_big_tit">本地生活</p>
              <div class="tool_tip_big_item" @click="toPage('/catering')">
                <img src="@/assets/home/head_cy.png" />
                <div>
                  <p>百池餐饮</p>
                  <p>一站式管理的餐饮营销拓客解决方案</p>
                </div>
              </div>
              <div class="tool_tip_big_item" @click="toPage('/wineshop')">
                <img src="@/assets/home/head_jl.png" />
                <div>
                  <p>百池酒旅</p>
                  <p>多业态酒店零售方案</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="menu_item">
          <div class="tool_tit">生态和合作</div>
          <div class="tool_tip tool_tip_132">
            <div class="tool_tip_item" style="padding-bottom: 30px" @click="toPage('/ecological')">菩成代理商</div>
            <!-- <div class="tool_tip_item" @click="toPage('/cooperate')">特约合伙人</div> -->
          </div>
        </div>
        <div class="menu_item" @click="toPage('/download')">
          <div class="tool_tit">下载</div>
        </div>
        <div class="menu_item">
          <div class="tool_tit">了解我们</div>
          <div class="tool_tip">
            <div class="tool_tip_item" @click="toPage('/know')">关于菩成</div>
            <div class="tool_tip_item" style="padding-bottom: 0" @click="toPage('/new')">新闻动态</div>
            <div class="tool_tip_item" @click="toPage('/join')">加入菩成</div>
          </div>
        </div>
        <!-- <div class="menu_item" @click="toPage('/help')">
          <div class="tool_tit">帮助中心</div>
        </div> -->
      </div>

      <div class="head_right">
        <button class="head_logon" @click="toPage('/register')">注册</button>

        <button class="head_login" @click="toHref()">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/utils/config";
import { Message } from 'element-ui';

export default {
  name: 'Head',
  data() {
    return {

    }
  },
  methods: {
    toPage(url){
      if(url){
        this.$router.push(url)
      } else {
        Message.success('暂未开放')
      }
    },
    toHref(){
      window.open(config.loginUrl)
    }
  }
}
</script>

<style scoped lang="less">
.head{
  width: 100%;
  height: 120px;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  border-bottom: 1px solid #BFBFBF;
  box-sizing: border-box;
  top: 0;
  .head_box{
    width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head_img{
      width: 224px;
      cursor: pointer;
    }
  }
  .head_menu{
    display: flex;
    align-items: center;
    height: 100%;
    .menu_item{
      color: #252525;
      font-size: 16px;
      padding: 0 22px;
      height: 100%;
      font-family: "Microsoft YaHei-Regular";
      position: relative;
      cursor: pointer;
      &:hover{
        color: #BA1B21;
        .tool_tip{
          display: block;
        }
        .tool_tip_big{
          display: block;
        }
        .tool_tip_unlita{
          visibility: visible;
        }
      }

      .tool_tit{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .tool_tip_132{
        width: 132px!important;
      }

      .tool_tip{
        position: absolute;
        box-shadow: 0px 3px 11px 0px rgba(217,27,27,0.05);
        background: #FFFFFF;
        width: 116px;
        left: 0;
        top: 120px;
        display: none;
        box-sizing: border-box;
        .tool_tip_item{
          font-size: 14px;
          color: #252525;
          padding: 30px;
          &:hover{
            color: #BA1B21;
          }
          &:first-child{
            padding-bottom: 0;
          }
        }
      }

      .tool_tip_big{
        position: absolute;
        box-shadow: 0px 3px 11px 0px rgba(217,27,27,0.05);
        background: #FFFFFF;
        width: 560px;
        height: 300px;
        left: -183px;
        top: 120px;
        padding: 16px;
        box-sizing: border-box;
        display: none;
        .tool_tip_big_box{
          text-align: left;
          .tool_tip_big_tit{
            color: #252525;
            font-size: 14px;
          }
          .tool_tip_big_item{
            display: flex;
            align-items: center;
            margin-top: 16px;
            img{
              width: 43px;
              height: 47px;
            }
            div{
              margin-left: 8px;
              p:nth-child(1){
                color: #252525;
                font-size: 14px;
              }
              p:nth-child(2){
                color: #434552;
                font-size: 12px;
              }
            }
          }
        }
      }

      .tool_tip_unlita{
        position: absolute;
        box-shadow: 0px 3px 11px 0px rgba(217,27,27,0.05);
        background: #FFFFFF;
        width: 643px;
        height: 300px;
        left: -183px;
        top: 120px;
        padding: 28px 45px;
        box-sizing: border-box;
        display: flex;
        visibility: hidden;
        .tool_tip_un_box{
          text-align: left;
          width: 50%;
          .tool_tip_big_tit{
            color: #252525;
            font-size: 14px;
          }
          .tool_tip_big_item{
            display: flex;
            align-items: center;
            margin-top: 16px;
            img{
              width: 40px;
              height: 40px;
            }
            div{
              margin-left: 8px;
              p:nth-child(1){
                color: #252525;
                font-size: 14px;
              }
              p:nth-child(2){
                color: #434552;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .head_right{
    button{
      border-radius: 8px;
      box-sizing: border-box;
      font-size: 16px;
      padding: 5px 20px;
      cursor: pointer;
      &:hover{
        opacity: 0.8;
      }
    }
    .head_logon{
      border: 1px solid #BA1B21;
      background-color: #fff;
      color: #BA1B21;
      margin-right: 44px;
    }
    .head_login{
      border: 1px solid #BA1B21;
      background-color: #BA1B21;
      color: #fff;
    }
  }
}
</style>