import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  // mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/home/index.vue'),
      meta: { title: '官网', isHidden: false },
    },
    {
      path: '/wineshop',
      name: 'Wineshop',
      component: () => import('@/views/solution/wineshop.vue'),
      meta: { title: '解决方案-酒店', isHidden: false }
    },
    {
      path: '/catering',
      name: 'Catering',
      component: () => import('@/views/solution/catering.vue'),
      meta: { title: '解决方案-餐饮', isHidden: false }
    },
    {
      path: '/qw',
      name: 'Qw',
      component: () => import('@/views/products/qw.vue'),
      meta: { title: '产品与服务-企微', isHidden: false }
    },
    {
      path: '/pulife',
      name: 'Pulife',
      component: () => import('@/views/products/pulife.vue'),
      meta: { title: '产品与服务-菩享汇', isHidden: false }
    },
    {
      path: '/business',
      name: 'Business',
      component: () => import('@/views/products/business.vue'),
      meta: { title: '产品与服务-菩成私域运营服务', isHidden: false }
    },
    {
      path: '/douyin',
      name: 'Douyin',
      component: () => import('@/views/products/douyin.vue'),
      meta: { title: '产品与服务-抖音代运营', isHidden: false }
    },
    {
      path: '/life',
      name: 'Life',
      component: () => import('@/views/products/life.vue'),
      meta: { title: '产品与服务-菩成生活', isHidden: false}
    },
    {
      path: '/marketing',
      name: 'Marketing',
      component: () => import('@/views/products/marketing.vue'),
      meta: { title: '产品与服务-数字营销全案服务', isHidden: false}
    },
    {
      path: '/know',
      name: 'Know',
      component: () => import('@/views/know/index.vue'),
      meta: { title: '关于我们', isHidden: false }
    },
    {
      path: '/download',
      name: 'Download',
      component: () => import('@/views/download/index.vue'),
      meta: { title: 'APP下载', isHidden: false }
    },
    {
      path: '/cooperate',
      name: 'Cooperate',
      component: () => import('@/views/cooperate/index.vue'),
      meta: { title: '特约合伙人', isHidden: false }
    },
    {
      path: '/ecological',
      name: 'Ecological',
      component: () => import('@/views/ecological/index.vue'),
      meta: { title: '菩成代理商', isHidden: false }
    },
    {
      path: '/help',
      name: 'Help',
      component: () => import('@/views/help/index.vue'),
      meta: { title: '帮助中心', isHidden: false }
    },
    {
      path: '/helpDetail',
      name: 'HelpDetail',
      component: () => import('@/views/help/detail.vue'),
      meta: { title: '帮助详情', isHidden: false }
    },
    {
      path: '/new',
      name: 'New',
      component: () => import('@/views/new/index.vue'),
      meta: { title: '新闻中心', isHidden: false }
    },
    {
      path: '/newDetail',
      name: 'NewDetail',
      component: () => import('@/views/new/detail.vue'),
      meta: { title: '新闻详情', isHidden: false }
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/login/register.vue'),
      meta: { title: '注册', isHidden: true }
    },
    {
      path: '/join',
      name: 'Join',
      component: () => import('@/views/join/index.vue'),
      meta: { title: '加入我们', isHidden: false }
    }
  ]
})

router.afterEach((to, from, next) =>{
  window.scrollTo(0,0)
})

router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = '菩成互联-' + to.meta.title
  }
  next()
})

export default router
