import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'animate.css'
import axios from 'axios'

import { Tooltip,Icon,Tabs,TabPane,Pagination,Input } from 'element-ui'

Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(Tooltip)
Vue.use(Icon)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Input)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
