<template>
  <div id="app">
    <div class="container" v-if="!isHidden">
      <Head />

      <div class="content">
        <router-view></router-view>
      </div>

      <Foot />
    </div>

    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import './assets/index.less'

import Head from '@/components/head.vue'
import Foot from '@/components/foot.vue'

export default {
  name: 'App',
  components: {
    Head,
    Foot
  },
  data(){
    return {
      isHidden: false
    }
  },
  watch:{
    $route(to, from){
      this.isHidden = to.meta.isHidden
    }
  }
}
</script>

<style lang="less" scoped>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  margin: 0;
  padding: 0;
} */

.container{
  width: 100%;
  min-height: 100vh;
  .content{
    width: 100%;
    padding-top: 120px;
  }
}
</style>
