<template>
  <div class="foot">
    <div class="foot_top">
      <div class="foot_top_cen">
        <div class="left">
          <div class="left_item">
            <p class="tit">产品和服务</p>
            <!-- <p class="lab" @click="toPage('/qw')">菩成私域宝</p> -->
            <p class="lab" @click="toPage('/life')">菩成生活</p>
            <!-- <p class="lab" @click="toPage('/business')">私域运营</p> -->
            <!-- <p class="lab" @click="toPage('/douyin')">抖音代运营</p> -->
          </div>
          <div class="left_item">
            <p class="tit">生态和合作</p>
            <p class="lab" @click="toPage('/ecological')">菩成代理商</p>
            <!-- <p class="lab" @click="toPage('/cooperate')">特约合伙人</p> -->
          </div>
          <div class="left_item">
            <p class="tit">关于菩成</p>
            <p class="lab" @click="toPage('/know')">公司介绍</p>
            <p class="lab" @click="toPage('/new')">新闻动态</p>
            <p class="lab" @click="toPage('/join')">加入我们</p>
          </div>
          <!-- <div class="left_item">
            <p class="tit">解决方案</p>
            <p class="lab" @click="toPage('/catering')">百池餐饮</p>
            <p class="lab" @click="toPage('/wineshop')">百池酒旅</p>
          </div> -->
          <!-- <div class="left_item">
            <p class="tit">服务支持</p>
            <p class="lab" @click="toPage('/help')">帮助中心</p>
          </div> -->
          <div class="left_item">
            <p class="tit">联系我们</p>
            <p class="lab default">电话咨询:</p>
            <p class="lab default">0592-5699858</p>
            <p class="lab default">邮箱:</p>
            <p class="lab default">info@pulife.com.cn</p>
          </div>
        </div>
        <div class="right">
          <div class="right_item">
            <img src="@/assets/pulife_hao.jpg" />
            <p>公众号</p>
          </div>
          <div class="right_item">
            <img src="@/assets/pulife_chengxu.png" />
            <p>小程序</p>
          </div>
          <!-- <div class="right_item">
            <img src="@/assets/pulife_wang.png" />
            <p>菩成网</p>
          </div> -->
        </div>
      </div>
    </div>
    <div class="foot_foot">
      <div class="foot_foot_cen">
        <p>Copyright@2019-2023  厦门菩成互联科技有限公司  闽ICP备2022006922号</p>
        <p>电信增值业闽B2-20221300  第二类医疗器械20234044号  医疗器械网络销售（闽）网械销售第35027829号  计算机软件著名权软著第E0128900号</p>
        <p>网络文化经营许可证 闽网文（2023）0269-014号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Foot',
  data() {
    return {

    }
  },
  methods: {
    toPage(url){
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="less">
.foot{
  width: 100%;
  // height: 440px;
  background-color: #000000;
  .foot_top{
    .foot_top_cen{
      width: 1280px;
      height: 360px;
      margin: 0 auto;

      display: flex;
      align-items: center;
      .left{
        display: flex;
        flex: 1;
        .left_item{
          width: calc(100% / 6);
          text-align: left;
          .tit{
            color: #fff;
            font-size: 16px;
          }
          .lab{
            color: rgba(255, 255, 255, 0.80);
            font-size: 12px;
            margin-top: 13px;
            cursor: pointer;
            &:hover{
              opacity: 0.8;
            }
          }
          .default{
            cursor: default;
          }
        }
      }
      .right{
        display: flex;
        align-items: center;
        width: 240px;
        .right_item{
          width: 70px;
          margin-right: 15px;
          &:last-child{
            margin-right: 0;
          }
          img{
            width: 70px;
            height: 70px;
          }
          p{
            text-align: center;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.80);
            margin-top: 15px;
          }
        }
      }
    }
  }
  .foot_foot{
    border-top: 2px solid #606266;
    .foot_foot_cen{
      width: 1280px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      p{
        text-align: left;
        font-size: 12px;
        color: #606266;
      }
    }
  }
}
</style>