// 测试环境
// const baseUrl = 'http://192.168.0.205:8080'
// const baseUrl = 'https://api.pulife.com.cn'
const baseUrl = ''

// 登录跳转路径
const loginUrl = 'http://mch.pulife.com.cn/user/login?redirect=%2F'

// api接口路径
const path = {
    getTopNavigationList: '/ow/system/topNavigation/getTopNavigationList',          // 获取顶部导航列表
    getBottomNavigationList: '/ow/system/bottomNavigation/getBottomNavigationList', //获取底部导航栏列表
    getCarouselList: '/ow/system/carousel/getCarouselList',                         // 获取轮播图列表
    getNewslist: '/ow/system/owNews/getNewslist',                                   // 获取新闻列表
    createContacts: '/ow/system/contact/insertContact',                             // 新建联系人
    getMenu: '/ow/system/v1/help/menu',                                             // 获取类目列表
    getNews: '/ow/system/v1/help/news/',                                            // 获取新闻列表
    searchNews: '/ow/system/v1/help/news/searchNews/',                              // 搜索上架的新闻
    getSchemeList: '/ow/system/scheme/getSchemeList',                               // 获取方案内容
    getNewsDetail: '/ow/system/owNews/getNews',                                     // 获取文章详情
    getComShowList: '/ow/system/comShow/getComShowList',                            // 获取公司展示
    getProductlist: '/ow/system/productManage/getProductlist/',                     // 获取产品列表
    getProductShowList: '/ow/system/productShow/getProductShowList',                // 获取产品展示列表
    getProductShow: '/ow/system/productShow/getProductShow/',                       // 获取产品展示详情
    getScheme: '/ow/system/scheme/getScheme',                                       // 获取方案内容详情
    getCode: '/message/v1/send/code/',                                              // 获取验证码
    getRegister: '/user/v3/life/register',                                          // 本地生活注册
    downloadApk: '/user/v1/system/version',
}

export default {
    baseUrl,
    path,
    loginUrl
}